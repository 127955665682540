import React from "react"
import Routes from "./router/Routes"


const App = () => {
  return (
    <div className="main">
      <Routes/>
    </div>
  );
}

export default App;

