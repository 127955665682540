import React from "react";
import HomeLight from "../views/all-home-version/HomeLight";

const Routes = () => {
  return (
    < HomeLight/>
  );
};

export default Routes;
