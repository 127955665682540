import React from "react";
import Social from "./Social";

const Home = () => {
  return (
    <>
      <div className="me">
        <div className="content">
          <div className="avatar">
            <div
              className="image avatar_img"
              style={{
                backgroundImage: "url(assets/img/me.png)",
              }}
            ></div>
          </div>
          <div className="details">
            <h3 className="name">Mathias Beugnon</h3>
            <p className="job">
              I design and develop solutions that solve users' problems.
            </p>
            {/*<Social />*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
